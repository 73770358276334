import React, { useCallback, useEffect, useState } from 'react';
import UploadHeader from '../../layouts/UploadHeader';
import Button from '../../components/Button/Button';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './css/Upload.css'; 
import { useSelector } from 'react-redux';
import { setDocumentTitle } from '../../services/titleService'; // Adjust the import path as necessary
import ProgressBar from '../../components/ProgressBar/ProgressBar';

import { useNavigate } from 'react-router-dom';
import Spinner from '../../components/Spinner/Spinner';
import { motion } from "framer-motion";


const DocumentUpload = () => {
    const navigate = useNavigate();
    const [files, setFiles] = useState([]);
    const [isUploaded, setIsUploaded] = useState(false); // Upload status variable
    const [isHighlighted, setIsHighlighted] = useState(false); // New state to manage highlight

    // Handle file drop
    const handleDrop = useCallback((event) => {
        event.preventDefault();
        setIsHighlighted(false); // Remove highlight on drop
        setIsUploaded(false); // Reset upload status

        const droppedFiles = Array.from(event.dataTransfer.files);
        // Filter to only include PDF files
        const pdfFiles = droppedFiles.filter(file => file.type === 'application/pdf');

        if (pdfFiles.length > 0) {
            setFiles((prevFiles) => [...prevFiles, ...pdfFiles]);
        } else {
            toast.error('Please upload only PDF files.');
        }
    }, []);

    useEffect(() => {
        setDocumentTitle("Add Document"); // Set title with suffix for Dashboard
    }, []);

    // Simulate file upload after 3 seconds
    useEffect(() => {
        if (files.length > 0) {
            const uploadTimeout = setTimeout(() => {
                console.log('Files uploaded:', files);
                setIsUploaded(true); // Set upload status to true after files are "uploaded"
            }, 3000); // 3 seconds delay

            return () => clearTimeout(uploadTimeout); // Clear timeout if files change or component unmounts
        }
    }, [files]);

    // Access the user details from the Redux store
    const { userId, email } = useSelector((state) => state.user);

    // Optional: You can handle the case where userId or email might be undefined
    if (!userId) {
        return <Spinner/>; // Loader or message until user details are available
    }

    // Handle drag over event
    const handleDragOver = (event) => {
        event.preventDefault();
        setIsHighlighted(true); // Add highlight on drag over
    };

    // Handle drag leave event
    const handleDragLeave = () => {
        setIsHighlighted(false); // Remove highlight when dragging leaves
    };

    // Handle file input click
    const handleClick = () => {
        document.getElementById('file-input').click();
    };

    // Handle file input change
    const handleFileChange = (event) => {
        const selectedFiles = Array.from(event.target.files);
        // Filter to only include PDF files
        const pdfFiles = selectedFiles.filter(file => file.type === 'application/pdf');

        if (pdfFiles.length > 0) {
            setFiles((prevFiles) => [...prevFiles, ...pdfFiles]);
        } else {
            toast.error('Please upload only PDF files.');
        }

        setIsUploaded(false); // Reset upload status
    };

    // Handle file reset
    const handleReset = () => {
        setIsUploaded(false);
        if(files.length)
        setFiles([]); // Resetting the files to an empty array
        else
        navigate(-1)
    };

    // Generate file summary
    const generateSummary = () => {
        navigate('/generate-summary')
    }

    return (
        <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
            <UploadHeader/>

            <div className='upload-wrapper'>
                <div className='container'>
                    <h2 className='document-title'>Add Document</h2>

                    <div className='upload-area'>
                        <label>Upload file <span>*</span></label>
                    </div>

                    {/* UPLOAD DRAG AREA */}
                    {!files.length && (
                        <div 
                            className={`upload-file-drag ${isHighlighted ? 'highlight' : ''}`}
                            onDrop={handleDrop}
                            onDragOver={handleDragOver}
                            onDragLeave={handleDragLeave}
                            onClick={handleClick}
                        >
                            <div className='upload-content'>
                                <button className='btn btn-upload' type='button'>
                                    <img src='/assets/upload.svg' alt='Upload' />
                                </button>
                                <p className='long-text'>Choose a file or drag & drop it here.</p>
                                <p className='short-text'>PDF & DOC formats, up to 5 MB.</p>
                                <input
                                    type="file"
                                    id="file-input"
                                    accept="application/pdf" // Only accept PDF files
                                    style={{ display: 'none' }}
                                    onChange={handleFileChange}
                                />
                                <label htmlFor="file-input">
                                    <Button text="Browse File" cssClass="btn-light btn-white filters-btn" />
                                </label>
                            </div>
                        </div>
                    )}

                    {/* UPLOADING FILES AREA */}
                    {files.length > 0 && (
                        <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 1 }}>
                        <div className={`uploading-file-list ${isUploaded ? 'uploaded-success' : ''}`}>
                            <div className="file-list">
                                <div className='row'>
                                    <div className='col-sm-8'>
                                        <div className='d-flex'>
                                            <img src='/assets/pdf.jpg' width={32} height={40} alt='pdf-icon' />
                                            <div className='file-info-area'>
                                                <h4>{files[0].name}</h4>
                                                <div className="d-flex file-details">
                                                    <div className='kb-dtls'>
                                                        {!isUploaded && (
                                                            <>
                                                                0 KB of 120 KB
                                                            </>
                                                        )}
                                                        {isUploaded && (
                                                            <>
                                                                120 KB
                                                            </>
                                                        )}
                                                    </div>

                                                    {!isUploaded && (
                                                        <div className='upload-sympls d-flex'>
                                                            <img src='/assets/spinner.gif' alt='Uploading' />
                                                            <span>Uploading...</span>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-sm-4'>
                                        <div className='d-flex justify-content-end file-progress-meter align-items-center'>
                                            {!isUploaded && <ProgressBar value={25} />}
                                            {isUploaded && (
                                                <div className='d-flex files-has-been-uploaded align-items-center'>
                                                    <img src='/assets/check-mark-success.svg' className='file-has-uploaded' alt='Success' />
                                                    <p>Uploaded!</p>
                                                </div>
                                            )}
                                            <img 
                                                src='/assets/close.svg' 
                                                onClick={handleReset} 
                                                alt='Remove' 
                                                className='file-close cursor-pointer' 
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </motion.div>
                    )}

            <div className='upload-action d-flex justify-content-end'>
                <Button text="Cancel" onClick={handleReset} cssClass="btn-light btn-white filters-btn mr-12px" />
                <Button 
                    text="Summarize" 
                    icon={`${!isUploaded ? 'summary-icon-disabled' : 'summary-icon'}.svg`}
                    onClick={isUploaded ? generateSummary : null}  
                    cssClass={`btn-primary btn-blue ${!isUploaded ? 'btn-disabled' : ''}`}  
                    />
            </div>


                </div>
            </div>


            

            <ToastContainer position="top-center" />
            </motion.div>
    );
};

export default DocumentUpload;
