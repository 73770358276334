import React from 'react';
import './Admin.css';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

const UploadHeader = ({showLogo,hideMarginBottom}) => {
    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1);  // Navigates to the previous page in history
    };
    return (
      <div className={`admin-header ${hideMarginBottom ? 'border-bottom-0 mb-0':''}`}>
        <div className='container'>
            <div className='row'>
              <div className='col-sm-4'>
                        { showLogo && (
                            <div className='d-flex logo-area align-items-center'>
                            <img src='/assets/logo.svg' alt='logo-caseup'/>
                            <h1><Link to="/dashboard">CASEUP</Link></h1>
                          </div>
                        )}

                      { !showLogo && (
                        <img src='/assets/back.svg' alt='Back' onClick={goBack} className='cursor-pointer' />
                      )}
                                    
                                
              </div>
              <div className='col-sm-8'>
                  <div className='d-flex justify-content-end document-close'>
                  <img src='/assets/close.svg' alt='Close' onClick={goBack} className='cursor-pointer' />
                  </div>
              </div>
            </div>
            
        </div>
      </div>
    );
  };

  export default UploadHeader;