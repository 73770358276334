import React, { useEffect } from 'react';
import UploadHeader from '../../layouts/UploadHeader';
import BlueContainer from '../../layouts/BlueContainer';
import { useSelector } from 'react-redux';
import { setDocumentTitle } from '../../services/titleService'; // Adjust the import path as necessary
import './css/Upload.css';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button/Button';
import Spinner from '../../components/Spinner/Spinner';
import { motion } from "framer-motion";

const GenerateSummary = () => {
    const navigate = useNavigate();
    useEffect(() => {
        setDocumentTitle("Generate Summary"); // Set title with suffix for Dashboard
    }, []);

    // Access the user details from the Redux store
    const { userId, email } = useSelector((state) => state.user);

    // Optional: You can handle the case where userId or email might be undefined
    if (!userId) {
        return <Spinner/>; // Loader or message until user details are available
    }
    const handleReset = () => {
        navigate('/add-document')
    };

    return (
        <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}>
            <UploadHeader showLogo={true} hideMarginBottom={true}/>
            <BlueContainer>
                <div className='box-white text-center'>

                        <div className='summary-box-content'>
                            <div className="spinner-border text-primary customized-progress-cricle" >
                                    <span className="sr-only">Loading...</span>
                            </div>

                            <div className='summary-box-title'>
                                <h2><img src='/assets/magic.svg' alt='wand-magic-sparkles' /> Generating Summary</h2>
                                <p>This may take a while; consider minimizing the window and returning later.</p>
                            </div>
                        </div>
                        <hr/>
                        <div className='d-flex box-buttons justify-content-end'>
                        <Button text="Cancel" onClick={handleReset} cssClass="btn-light btn-white filters-btn mr-12px" />
                <Button 
                    text="Minimize" 
                    onClick={null}  
                    cssClass={`btn-primary btn-blue`}  
                    />

                        </div>
                        
                </div>
            </BlueContainer>
        </motion.div>
      );
};
    
export default GenerateSummary;