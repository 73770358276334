import React from 'react';
import './Admin.css';

const BlueContainer = ({ children }) => {  // Accept children as a prop
    return (
        <div className='blue-container'>
            <div className='container'>
                {children}  {/* Render the children here */}
            </div>
        </div>
    );
};
    
export default BlueContainer;
