// userSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userId: null,
  email: '',
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserDetails(state, action) {
      const { userId, email } = action.payload;
      state.userId = userId;
      state.email = email;
    },
  },
});

export const { setUserDetails } = userSlice.actions;
export default userSlice.reducer;
