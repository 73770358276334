import React from 'react';
import './Button.css';

function Button({ text, onClick, cssClass, icon }) {
  const isSvgIcon = icon && icon.endsWith('.svg');

  return (
    <button
      type="button"
      className={`btn ${cssClass}`}
      onClick={onClick}
      
    >
      {icon && isSvgIcon ? (
        <img src={`/assets/${icon}`} alt="icon" className="button-icon" />
      ) : (
        icon && <><i className={`fa-regular fa-${icon}`}></i>&nbsp; </>
      )}
      {text}
    </button>
  );
}

export default Button;
