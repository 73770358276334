import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes  } from 'react-router-dom';
import DocumentUpload from '../pages/Document/DocumentUpload';
import GenerateSummary from '../pages/Document/GenerateSummary';
import Spinner from '../components/Spinner/Spinner';

const Home = lazy(() => import('../pages/Home/Home'));
const ClerkSignUp = lazy(() => import('../pages/ClerkSignUp/ClerkSignUp'));
const ClerkSignIn = lazy(() => import('../pages/ClerkSignIn/ClerkSignIn'));
const Dashboard = lazy(() => import('../pages/Dashboard/Dashboard'));

const DashboardSubList = lazy(() => import('../pages/Dashboard/DashboardSubList'));

const ProtectedRoute = lazy(() => import('../routes/ProtectedRoute'));



function AppRoutes() {
  return (
    <Router>
      
      <Suspense fallback={<Spinner/>}>
        <Routes >
        
          <Route exact path="/" element={<Home/>} />
          <Route path="/signup" element={<ClerkSignUp/>} />
          <Route path="/login" element={<ClerkSignIn/>} />

          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />

          <Route
            path="/case-items"
            element={
              <ProtectedRoute>
                <DashboardSubList />
              </ProtectedRoute>
            }
          />

          <Route
            path="/add-document"
            element={
              <ProtectedRoute>
                <DocumentUpload />
              </ProtectedRoute>
            }
          />

<Route
            path="/generate-summary"
            element={
              <ProtectedRoute>
                <GenerateSummary/>
              </ProtectedRoute>
            }
          />
        </Routes >
      </Suspense>
      
    </Router>
    
  );
}

export default AppRoutes;