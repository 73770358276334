import React from 'react';

const Spinner = ({ value }) => {
  return (
    <div className='spinner-container p-4'>
        <div className='d-flex justify-content-center align-items-center'>
        <div className="spinner-border text-secondary global-progress-cricle" >
                                        <span className="sr-only">Loading...</span>
        </div>
        </div>
    </div>
    
  );
};

export default Spinner;
